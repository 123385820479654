<!--footer_section start-->

<footer class="footer_section">
    <div class="container">
       <div class="row">
          <div class="col-lg-12">
             <li class="ft_sec">
                <div class="ft_logo">
                   <a routerLink="/"><img src="//assets.ucleanlaundry.com/assets/images/logo.png"></a>
                </div>
                <div class="ft_text">
                   <p>UClean is India's largest Laundry & Dry Cleaning Chain with over 500+ stores spread across 150+ cities. we are also India's highest rated laundry brand.</p>
                </div>
             </li>
             <li class="ft_sec" id="ft_sec">
                <div class="ft_link">
                   <h4>Our Services</h4>
                   <ul class="col-lg-6 col-sm-6">
                      <li><a routerLink="services/DryCleaning">Dry Cleaning</a></li>
                      <li><a routerLink="services/LaundrybyKg">Laundry by Kg</a></li>
                      <li><a routerLink="services/PremiumLaundry">Premium Laundry</a></li>
                      <li><a routerLink="services/SteamIroning">Steam Ironing</a></li>
                      <li><a routerLink="services/ShoeCleaning">Shoe Cleaning</a></li>
                      <li><a routerLink="services/BagCleaning">Bag Cleaning</a></li>
                      <li><a routerLink="services/CurtainCleaning">Curtain Cleaning</a></li>
                   </ul>
                   <ul class="col-lg-6 col-sm-6">
                      <li><a routerLink="services/SofaCleaning">Sofa Cleaning</a></li>
                      <li><a routerLink="services/CarpetCleaning">Carpet Cleaning</a></li>
                   </ul>
                </div>
             </li>
             <li class="ft_sec" id="ft_sec3">
                <div class="ft_link">
                   <h4>Pages</h4>
                   <ul>
                     <li><a routerLink="uclean-store-near-you">Locate us</a></li>
                      <li><a routerLink="blogs" >Blog </a></li>
                      <li><a routerLink="careers">Career</a></li>
                      <li><a routerLink="media">Media </a></li>
                      <li><a routerLink="privacy-policy">Privacy Policy </a></li>
                      <li><a routerLink="terms-and-conditions">T&C </a></li>
                      <li><a routerLink="covid-19">COVID-19 </a></li>
                   </ul>
                </div>
             </li>
             <li class="ft_sec" id="ft_sec4">
                <div class="ft_link">
                   <h4>Contact us</h4>
                   <ul>
                      <li>
                         <p>Unit No. 114 & 115 Charm wood Plaza, Charm wood Village, Eros Garden, Suraj Kund, Faridabad, Haryana - 121009, India</p>
                      </li>
                      <li><a href="tel:+91 9999759911">+91 9999759911</a></li>
                      <li><a href="mailto:Support@Uclean.in">support@uclean.in </a></li>
                   </ul>
                </div>
             </li>
             <li class="ft_sec" id="ft_sec5">
                <div class="ft_link">
                   <h4>Follow us</h4>
                   <ul class="social_icon">
                      <li><a href="https://www.facebook.com/ucleanindia/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li><a href="https://twitter.com/ucleanindia" target="_blank"><i class="icon-x-logo" aria-hidden="true"></i></a></li>
                      <li><a href="https://www.linkedin.com/company/uclean/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      <li><a href="https://instagram.com/_uclean_?utm_medium=copy_link" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                      <li style="background: white; padding-bottom: 2px; border-radius: 6px;"><a href="https://www.youtube.com/channel/UCCB8jfgaSt-UJwALz9QBHRg" target="_blank" style="background: transparent; margin-top: -3px;"><img src="//assets.ucleanlaundry.com/assets/images/youtube-logo.png" alt=" UClean Laundry and Dry Cleaning"  style="padding: 5px;"></a></li>
                     </ul>
                   <div class="app_sec">
                      <p><a href="">Download the App</a></p>
                      <ul>
                         <li><a href="" target="_blank"><img src="//assets.ucleanlaundry.com/assets/img/appleicon.png"></a></li>
                         <li><a href="https://play.google.com/store/apps/details?id=com.ucleanlaundry" target="_blank" ><img src="//assets.ucleanlaundry.com/assets/img/playstoreicon.png"></a></li>
                         <li><a href="https://wa.me/919999759911?text=Hello%20U%20Clean" target="_blank"><img src="//assets.ucleanlaundry.com/assets/img/whatsappicon.png" alt=" UClean Laundry and Dry Cleaning" ></a></li>
                      </ul>
                   </div>
                </div>
             </li>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 copy_right">
             <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="copy_right_text">
                   <p>© 2024 UClean. All rights reserved</p>
                </div>
             </div>
             <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="copy_right_text">
                   <ul>
                      <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                      <li><a routerLink="terms-and-conditions">Terms and conditions</a></li>
                   </ul>
                </div>
             </div>
          </div>
       </div>
    </div>
 </footer>

 <!--footer_section end-->
