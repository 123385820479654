import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bag-cleaning',
  templateUrl: './bag-cleaning.component.html',
  styleUrls: ['./bag-cleaning.component.scss']
})
export class BagCleaningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
