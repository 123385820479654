<!-- inner_banner_section start-->

<section class="inner_banner_section_services">
   <div class="container">
      <div class="row">
         <div class="inner_banner_block">
            <div class="inner_banner_tilte">
               <!-- <h1>UCLEAN SERVICES</h1> -->
            </div>
         </div>
      </div>
   </div>
</section>

<!-- inner_banner_section end-->

<!-- services_page_section start-->


<section class="page_services_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style="height: 20px;">
            <div class="sec_title_bar">
               <h2>Our Services</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services">
            <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
               <div class="tab-pane active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div id="owl-demo" class="owl-carousel owl-theme">
                     <div class="item"  (click)="navigateToService(data.name)"  [class.phase-active]="activeButton === i" *ngFor="let data of services; index as i;">
                        <div class="serv_block_page_page">
                           <div class="service_img_sec">
                              <img src="{{data.img}}">
                           </div>
                           <div class="service_text_sec">
                              <p>{{data.name}}</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="ab_about_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
            <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
               <div class="ab_about_img">
                  <img src="//assets.ucleanlaundry.com/assets/images/service_main_image_11zon.webp">
               </div>
            </div>
            <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
               <div class="ab_service_title">
                  <h2>LAUNDRY</h2>
                  <span></span>
                  <p> The laundry process at UClean begins by sorting the clothes. After sorting, similar clothes are
                     washed together. The best detergent brands are used for washing clothes. The washing machine is
                     then loaded by adjusting the right setting for your clothes. The freshwater rinse during the
                     process helps in eliminating the dirt and stains from the clothes. We air-dry your clothes in
                     anti-bacterial stainless-steel air-dryers that eliminate Direct UV rays from the sun which react with the colour
                     pigments of your clothes and create bleaching effect. After the laundry cycle ends,High pressure
                     vacuum steam iron is done for that perfect finishing
                     . As per CDC and WHO, steam sterilization is best and safest way to sanitize your clothes.
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="ab_promise_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon">
                     <img src="//assets.ucleanlaundry.com/assets/images/affordable_rates_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p>Affordable rates</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon">
                     <img src="//assets.ucleanlaundry.com/assets/images/skin_friendly_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p>Skin friendly</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon" style="padding-top: 20px">
                     <img src="//assets.ucleanlaundry.com/assets/images/uv_safe_air_drying_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p> UV Safe Air Drying</p>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 service">
               <div class="promise_block pro-ser">
                  <div class="serv_promise_icon" style="padding-top: 12px">
                     <img src="//assets.ucleanlaundry.com/assets/images/uses_less_fresh_water_11zon.webp">
                  </div>
                  <div class="promise_text pro-ser-p">
                     <p>Uses less & Fresh Water</p>
                  </div>
               </div>
            </div>
         </div>
         <div class="text-center">
            <button type="button" routerLink="/" class="btn btn-primary place-order">Place Order</button>
         </div>
      </div>
   </div>
</section>
<section class="upis_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="sec_title_bar">
               <h2>USPs</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>It is Cheaper, its Laundry by Kilo</p>
                  </span>
                  <p>YES! Outsourcing your laundry is cheaper than washing clothes at home. A family of four
                     spends approximately Rs. 2500 per month on...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>It Saves Time, Space & Water</p>
                  </span>
                  <p>It takes about 40-50 hours per month to do the laundry for a family of 4. Further, you need a
                     dedicated space for line drying your clothes, ...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>High-Quality Cleaning</p>
                  </span>
                  <p>Our team of dedicated experts are well-trained to take care of varied garments that you send
                     to us.
                     At UClean, we focus on providing adequate care...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>Skin-friendly</p>
                  </span>
                  <p>Unlike your homes, our stores carry a buffet of detergents, emulsifiers, stain treatment kits,
                     softeners, antiseptics, whiteners and finishing...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>Air Drying with Temperature Control</p>
                  </span>
                  <p>Line drying under the sun makes your garment dull and dry. Direct UV rays from the sun
                     react with the colour pigments of your clothes and create...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
            <li class="col-lg-6 col-md-6 col-sm-6 col-xs-12 rr_block">
               <div class="reviews_sec">
                  <span>
                     <p>High-pressure Steam iron instead of hot plate steam iron (Only done for Wash & Iron
                        Service)</p>
                  </span>
                  <p>Have you ever noticed shine-marks on your polyester trousers or those poly-georgette tops?
                     This happens because the home iron or...</p>
                  <span>
                     <p>Read more</p>
                  </span>
               </div>
            </li>
         </div>
      </div>
   </div>
</section>
<section class="laundry_process_section">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="sec_title_bar">
                  <h2>The Laundry Process</h2>
               </div>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>Clothes are carefully sorted based on colour, material, texture, soiling level etc.</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>Clothes with extra soiling & oil stains are treated to a special enzyme soak before washing</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>Softener is added as per finishing required</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 uper">
            <div class="promise_block">
               <div class="promise_text up">
                  <p>High pressure vacuum steam iron is done for that perfect finishing</p>
               </div>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
         <div class="process_structure">
            <div class="process_structure_img">
               <ul>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Tagging</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/Tagging_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/sorting_11zon.webp">
                     </div>
                     <div class="steps_name">
                        <p>Sorting</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1.png">
                  </li>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Treatment</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/cuff_collar_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/pre_wash_11zon.webp">
                     </div>
                     <div class="steps_name">
                        <p>Pre-Wash</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1.png">
                  </li>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Washing</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/washing_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/softener_11zon.webp">
                     </div>
                     <div class="steps_name">
                        <p>Softener</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1.png">
                  </li>
                  <li class="uper">
                     <div class="steps_name">
                        <p>Drying</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/drying_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2.png">
                  </li>
                  <li class="down">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/ironing.png">
                     </div>
                     <div class="steps_name">
                        <p>Ironing</p>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top">
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Clothes are tagged to avoid potential mix up</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Cuff & collar are treated using special detergents</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Clothes are washed as per the load type</p>
               </div>
            </div>
         </div>
         <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 dwon">
            <div class="promise_block">
               <div class="promise_text down">
                  <p>Clothes are Air-dried in anti-bacterial stainless-steel dryers</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="laundry_process_section_mob">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
               <div class="sec_title_bar">
                  <h2>The Laundry Process</h2>
               </div>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pro-pad">
         <div class="process_structure_mob">
            <div class="process_structure_img_mob tagging tag-padd">
               <ul >
                  <li class="uper_mob">
                     <div class="steps_name_mob">
                        <p>Tagging</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/Tagging.png">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/sorting_11zon.webp">
                     </div>
                     <div class="steps_name_mob">
                        <p>Sorting</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2" style="margin-top:-7%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1_mob.png">
                  </li>
                  <li class="uper_mob">
                     <div class="steps_name_mob">
                        <p>Treatment</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/cuff_collar_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1"  style="margin-top: -9%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/pre_wash_11zon.webp">
                     </div>
                     <div class="steps_name_mob">
                        <p>Pre-Wash</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2" style="margin-top: -7%; margin-bottom: 2%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1_mob.png">
                  </li>
                  <li class="uper_mob">
                     <div class="steps_name_mob">
                        <p>Washing</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/washing_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/softener_11zon.webp">
                     </div>
                     <div class="steps_name_mob">
                        <p>Softener</p>
                     </div>
                  </li>
                  <li class="connect_imgs_2" style="margin-top: -7%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_1_mob.png">
                  </li>
                  <li class="uper_mob">
                     <div class="steps_name_mob" style="margin-left: 10%;">
                        <p>Drying</p>
                     </div>
                     <div class="img_section up">
                        <img src="//assets.ucleanlaundry.com/assets/images/drying_11zon.webp">
                     </div>
                  </li>
                  <li class="connect_imgs_1" style="margin-top: -7%;">
                     <img src="//assets.ucleanlaundry.com/assets/images/connect_2_mob.png">
                  </li>
                  <li class="down_mob">
                     <div class="img_section down">
                        <img src="//assets.ucleanlaundry.com/assets/images/ironing.png">
                     </div>
                     <div class="steps_name_mob">
                        <p>Ironing</p>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 lp">
         <div class="process_text">
         <p>The laundry process at UClean begins by sorting the clothes. After sorting, similar clothes are washed together. The best detergent brands are used for washing clothes. The washing
            machine is then loaded by adjusting the right setting for your clothes. The freshwater rinse
            during the process helps in eliminating the dirt and stains from the clothes. After the laundry
            cycle ends, the washer is unloaded and the clothes are sent for ironing. Ironing takes place
            with great attention to the garments.</p>
         </div>
      </div>
   </div>
</section>
<section class="services_faq_section service">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="sec_title_bar">
               <h2>Frequently Ask Questions</h2>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="accordion_one">
               <div class="panel-group" id="accordion_oneLeft">
                  <div class="panel panel-default">
                     <div class="panel-heading service">
                        <h4 class="panel-title" id="question"> <a data-toggle="collapse" data-parent="#accordion_oneLeft"
                              href="#collapseFiveLeftone" aria-expanded="false" class="collapsed">How much time will it take to wash my clothes?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftone" class="panel-collapse collapse" aria-expanded="false" role="tablist"
                        style="height: 0px;">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>For Wash & Fold, it takes up to 48 hours. while, for Wash & Iron, it takes anywhere between 2-3 days. These are standard delivery timings; however, it may take slightly longer due to factors beyond operational control. </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftTwo" aria-expanded="false">What if I need my clothes washed urgently?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftTwo" class="panel-collapse collapse" aria-expanded="false" role="tablist"
                        style="height: 0px;">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>We provide an emergency service where you can get your clothes washed and ironed or washed and folded on the same day. However, an express service charge is levied for such urgent orders. </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftThree" aria-expanded="false">Do I need to sort my clothes before sending them?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftThree" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>You do not need to sort your clothes. We encourage you to provide care instructions for special garments, however, our expert team is well-versed with the process of sorting. They will look after the sorting process for your garments. We expect you to inform about colour bleed possibilities, because we do not take responsibility for the same.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftfour" aria-expanded="false">Do you use fresh water for my laundry?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftfour" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Yes. At UClean, we are obsessed with Quality and Hygiene. We use fresh water for every wash cycle.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftfive" aria-expanded="false">Do you use harsh detergents?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftfive" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>No. we use PH neutral and eco-friendly detergents for washing. In fact, we have different detergent compositions for different garment types. The process is gentle on your clothes, and we believe in treating your clothes with utmost care.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftsix" aria-expanded="false"> Do You do stain removal in laundry by kg?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftsix" class="panel-collapse collapse" aria-expanded="false" role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>We do complimentary cuff and collar cleaning in laundry by kg. All washable stains are cleaned during washing process. However, hard stain treatment is chargeable depending on the size and hardness of stain. </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="panel panel-default">
                     <div class="panel-heading">
                        <h4 class="panel-title" id="question"> <a class="collapsed" data-toggle="collapse"
                              data-parent="#accordion_oneLeft" href="#collapseFiveLeftseven" aria-expanded="false">Do you take guarantee of colour bleeding?</a> </h4>
                     </div>
                     <div id="collapseFiveLeftseven" class="panel-collapse collapse" aria-expanded="false"
                        role="tablist">
                        <div class="panel-body">
                           <div class="text-accordion">
                              <p>Our team makes informed sorting decision based on their experience. However, it is not possible to check every garment for colour bleeding in bulk washing. Thus, we always advise our customers to check for colour bleed and inform our store team about the same. We do not take responsibility for damages due to colour bleed.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- <div class="load_btn">
                  <a href="">Load More</a>
               </div> -->
            </div>
         </div>
      </div>
   </div>
</section>