<section class="hp_banner_section">
  <div class="container">
    <div class="row">
      <div class="hp_banner_block">
        <div class="hp_banner_tilte">
          <h1 class="baner">Find your nearest Laundromat!</h1>
        </div>
        <span class="error-block" [hidden]=!showError>Please select the location</span>
        <!-- <div class="hp_banner_form">
          <div class="md_form">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <input type="text" #inputVal (keyup)="getVal(inputVal.value)" (click)="currentLoc()" (focusout)="hideCurrentLoc()" placeholder="Enter location" id="searchRegion" [(ngModel)]="searchLocation">
          </div>
          <div class="order_button_sec">
            <div class="md_button">
              <button class="" (click)="takeToBuildOrder()">Order Now</button>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="md_button">
            <button (click)="setLocation()"  data-toggle="modal" data-target="#locationModal">Order Now</button>
          </div>
        </div>
        <!-- <div *ngIf="showCurrentLoc" class="current_loc">
          <button style="display: inline-block;" (click)="setLocation();"><i
              class="fa fa-crosshairs" aria-hidden="true"></i>Choose your current location</button>
        </div> -->
        <div class="row" *ngIf="showLoader">
          <div class="col-xs-12">
            <div id='loading_wrap'></div>
          </div>
        </div>

        <div class="app_sec">
          <ul>
            <!-- <li><a href="" target="_blank"><img src="//assets.ucleanlaundry.com/assets/images/apple_icon.png"></a></li>
            <li><a href="" target="_blank"><img src="//assets.ucleanlaundry.com/assets/images/play_stroe.png"></a></li> -->
            <!-- <li><a class="hero-section-icon-wrapper" href="" target="_blank"
                style="background: #eaeaea; height: 40px; width: 40px;"></a></li> -->
                <!-- <img class="hero-action"
                  src="//assets.ucleanlaundry.com/assets/images/Group 17074.svg" (click)="openWhatsapp()"> -->
          </ul>
          <div class="app-three-logo">
            <img class="three-logo" src="//assets.ucleanlaundry.com/assets/img/whatsappicon.png" (click)="openWhatsapp()">
            <img class="three-logo" src="//assets.ucleanlaundry.com/assets/img/appleicon.png" (click)="openApp()">
            <img class="three-logo" src="//assets.ucleanlaundry.com/assets/img/playstoreicon.png" (click)="openApp()">
          </div>
          <p (click)="openWhatsapp()"><a href="javascript: void(0)">Place Your Order Now</a></p>
        </div>

      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="locationModal" tabindex="-1" role="dialog" aria-labelledby="locationModalLabel"
	aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
            <div class="modal-body">
                <div class="modal-body-container">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="locationModal w100">
                      <h6  data-dismiss="modal" aria-label="Close">
                        <i class="fa fa-arrow-left"></i>
                        Set Delivery location
                      </h6>
                      <div class="md_form block">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        <input type="text" #inputVal (keyup)="getVal(inputVal.value)" (click)="currentLoc()" (focusout)="hideCurrentLoc()" placeholder="Enter location" id="searchRegion" [(ngModel)]="searchLocation">
                      </div>
                      <div class="map-details">
                        <div class="map">
                          <div class="map-section">
                            <app-leaflet-map #mapCompRef [isLoadCurrentLocationOnInit]="true" [defaultMapLocation]="mapLocation" (mapInit)="onMapInit($event)" (mapLocationDragged)="onLocationConfirm($event)" (mapCurrentLocationRequest)="onLocationConfirm($event)"></app-leaflet-map>
                          </div>
                        </div>
                        <!-- <div class="current_loc text-center">
                          <button (click)="setLocation();"><i class="fa fa-crosshairs" aria-hidden="true"></i>Choose your current location</button>
                        </div> -->
                        <div class="address">
                          <div class="">
                            Address
                          </div>
                          <div>
                            <b>{{inputVal.value }}</b>
                          </div>
                        </div>
                      </div>
                      <div class="text-center w100">
                        <div class="btn btn-success" (click)="takeToBuildOrder()">CONFIRM LOCATION</div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>