import {Injectable, NgModule} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { Address } from 'src/app/models/addresses';
import { Subject } from 'rxjs';
import {Location} from 'src/app/models/location';
import { User } from 'src/app/models/user.model';
import { LocationService } from 'src/app/services/location.service';


@Injectable({
  providedIn: 'root'
})

export class PagesService {

  user = new Subject<User>();
  geoLocation = {} as Location;
  constructor(private httpclient: HttpClient ,
    private locationService: LocationService) {

      this.locationService.geoLocation.subscribe(location => {
      this.geoLocation = location;
    });
  }

  stroesListUrl = environment.baseUrl + 'api/v1/stores?franchise=UCLEAN';
  cityListUrl = environment.baseUrl + 'api/v1/cities';
  sendOTP = environment.baseUrl + 'api/v1/send_OTPL';
  loginUrl = environment.baseUrl + 'api/v1/login';
  servicesUrl = environment.baseUrl + 'api/v1/services?franchise=UCLEAN';
  storeDetailUrl = environment.baseUrl + 'api/v1/stores/laundry_and_dry_cleaners_in_';
  franchiseUrl = environment.baseUrl + 'api/v1/applyForFranchise';
  currenUserUrl = environment.baseUrl + 'api/v1/current_user';
  addressesUrl = environment.baseUrl + 'api/v1/addresses?franchise=UCLEAN';
  searchStoreUrl = environment.baseUrl + 'api/v1/stores/_search?franchise=UCLEAN&lat=28.5293121&lng=77.1484442&radius=10&city_id=1';
  logoutUrl = environment.baseUrl + 'api/v1/logout';
  updateUserProfileUrl = environment.baseUrl + 'api/v1/profile';
  orderHistoryUrl = environment.baseUrl + 'api/v1/orders?franchise=UCLEAN';
  createNewAddUrl = environment.baseUrl + 'api/v1/addresses';
  storePricesUrl = environment.baseUrl + 'api/v1/prices?franchise=UCLEAN&';
  cityPricesUrl = environment.baseUrl + 'api/v1/prices?franchise=UCLEAN&';
  mediaUrl = environment.baseUrl + 'api/v1/medias';

  getStores() {
    return this.httpclient.get(this.stroesListUrl);
  }

  cityList() {
    return this.httpclient.get(this.cityListUrl);
  }

  addresses() {
    return this.httpclient.get(this.addressesUrl);
  }

  orderHistory() {
    return this.httpclient.get(this.orderHistoryUrl);
  }

  services() {
    return this.httpclient.get(this.servicesUrl+'&lat='+ this.geoLocation.lat+'&lng='+this.geoLocation.lng);
  }

  storeDetail(store: string) {
    return this.httpclient.get(this.storeDetailUrl + store.replace(/-/g, "_"));
  }


  loginUser: any;

  loginData(formBody: any) {
    return this.httpclient.post(this.loginUrl, formBody);
  }

  senOTP(formBody: any) {
    return this.httpclient.post(this.sendOTP, formBody);
  }

  createNewAdd(formBody: any) {
    return this.httpclient.post(this.createNewAddUrl, formBody);
  }

  deleteAdd() {
    return this.httpclient.post(this.createNewAddUrl,
      { headers: new HttpHeaders({'Authorization': 'Bearer '+ localStorage.getItem('userToken')})});
  }

  logOut(){
   return this.httpclient.delete(this.logoutUrl);
  }

  franchise(formBody: any) {
    return this.httpclient.post(this.franchiseUrl, formBody);
  }

  updateUserProfile(formBody: any) {
    return this.httpclient.post(this.updateUserProfileUrl, formBody);
  }

  currentUser() {
    return this.httpclient.get(this.currenUserUrl);
  }

  searchStore(){
    return this.httpclient.get(this.searchStoreUrl)
      // { headers: new HttpHeaders({'lat=':this.lat+{'&lng=': this.lng}})});
  }

  private upersons: Address[] = [
  ];

  getAddFromData(): Address[] {
    return this.upersons;
  }

  addAdd(address: Address) {
    address.id = this.upersons.length + 1;
    this.upersons.push(address);

  }
  updateAdd(address: Address) {
    const index = this.upersons.findIndex(u => address.id == u.id);
    this.upersons[index] = address;
  }
  deleteUser(address: Address) {
    this.upersons.splice(this.upersons.indexOf(address), 1);
  }

  media() {
    return this.httpclient.get(this.mediaUrl);
  }

  public authUser(email: string, userId: string, token: any , expiresIn: any, _tokenExpDate:any ){
    const expiresDate:any = new Date(new Date().getTime() + expiresIn*1000);
    const user = new User(email, userId, token, expiresDate, _tokenExpDate );
    alert(user)
    this.user.next(user)
  }

  locs:any = {}

  delAdd:any

  set delAdds(value:any){
    this.delAdd = value;
  }

  get servs(){
    return this.delAdd;
  }

  locatonId: any;
  set locId(value: any) {
    this.locatonId = value;
  }

  strDtls: any;
  set storeData(value: any) {
    this.strDtls = value;
  }

  storeServices() {
    return this.httpclient.get(this.servicesUrl+'&store_id='+this.selstoreID);
  }

  cityServices() {
    return this.httpclient.get(this.servicesUrl+'&city_id='+this.selCityID);
  }

  storePrice() {
    return this.httpclient.get(this.storePricesUrl+'city_id='+this.selCityID+'&service_id='+this.selServiceID+'&store_id='+this.selstoreID);
  }

  cityPrice() {
    return this.httpclient.get(this.storePricesUrl+'city_id='+this.selCityID+'&service_id='+this.selServiceID);
  }

  selstoreID: any;
  set storeId(value:any) {
    this.selstoreID = value;
  }

  selCityID: any;
  set cityId(value:any) {
    this.selCityID = value;
  }

  selServiceID: any;
  set serviceId(value:any) {
    this.selServiceID = value;
  }

  selStoreCityId: any;
  set storeCityId(value:any) {
    this.selStoreCityId = value;
  }

  storeDtlCityName: any;
  set storeDtlCity(value:any) {
    this.storeDtlCityName = value;
  }

  storeDtlstoreCode: any;
  set storeDtlcode(value:any) {
    this.storeDtlstoreCode = value;
  }

  storeDtlLocalityName: any;
  set storeDtllocality(value:any) {
    this.storeDtlLocalityName = value;
  }

}
