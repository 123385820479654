import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.scss']
})
export class WhyUsComponent implements OnInit {

  serviceList = [
    { name: 'Quick Service', image: '//assets.ucleanlaundry.com/assets/images/QUICK_SERVICE.png'},
    { name: 'Doorstep Pick up and Drop', image: '//assets.ucleanlaundry.com/assets/images/DOORSTEP_PICK_UP_AND_DROP.png'},
    { name: 'Affordable per kilo pricing', image: '//assets.ucleanlaundry.com/assets/images/affordable_per_kilo_pricing.png'},
    { name: 'State-of-the-art Technology', image: '//assets.ucleanlaundry.com/assets/images/STATE_OF_THE_ART_TECHNOLOGY.png'},
    { name: 'We Save Water with every wash', image: '//assets.ucleanlaundry.com/assets/images/WE_SAVE_WATER-WITH_EVERY_WASH_CYCLE.png'},
    { name: 'Professional cleaning', image: '//assets.ucleanlaundry.com/assets/images/center_logo.png'},
    { name: 'Green-Certified Detergents', image: '//assets.ucleanlaundry.com/assets/images/GREEN_CERTIFIED_DETERGENTS.png'},
    { name: 'On-demand service also available', image: '//assets.ucleanlaundry.com/assets/images/ONDEMAND_EXPRESS_SERVICE_AVAILABLE.png'},
    { name: '100% Sanitized Clothes', image: '//assets.ucleanlaundry.com/assets/images/100_SANITIZED_and_MACHINE_DRY.png'},
    { name: 'Custom packaging Fold or Hang!', image: '//assets.ucleanlaundry.com/assets/images/CUSTOM_PACKAGING_FOLD_OR_HANGI.png'}
  ];

  constructor(private metaTagService: Meta) { }

  ngOnInit(): void {
  }

  openApp() {
    window.open('https://onelink.to/pv4ck7/', "_blank");
  }

}
