import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carpet-cleaning',
  templateUrl: './carpet-cleaning.component.html',
  styleUrls: ['./carpet-cleaning.component.scss']
})
export class CarpetCleaningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
