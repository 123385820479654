import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './main/navbar/navbar.component';
import { BannerComponent } from './main/home/banner/banner.component';
import { AboutComponent } from './main/pages/about/about.component';
import { ServicesComponent } from './main/pages/services/services.component';
import { PricingComponent } from './main/pages/pricing/pricing.component';
import { LocateUsComponent } from './main/pages/locate-us/locate-us.component';
import { OurServicesComponent } from './main/home/our-services/our-services.component';
import { WhyUsComponent } from './main/home/why-us/why-us.component';
import { HowItWorkComponent } from './main/home/how-it-work/how-it-work.component';
import { CustomerSectionComponent } from './main/home/customer-section/customer-section.component';
import { LatestBlogsComponent } from './blog/latest-blogs/latest-blogs.component';
import { LocationsComponent } from './main/home/locations/locations.component';
import { FooterComponent } from './main/footer/footer.component';
import { HomeComponent } from './main/home/home/home.component';
import { LoginComponent } from './main/pages/login/login.component';
import { SignUpComponent } from './main/pages/sign-up/sign-up.component';
import { MainBlogsComponent } from './blog/main-blogs/main-blogs.component';
import { FranchiseComponent } from './main/pages/franchise/franchise.component';
import { MediaComponent } from './main/pages/media/media.component';
import { PrivacyPolicyComponent } from './main/pages/privacy-policy/privacy-policy.component';
import { CareerComponent } from './main/pages/career/career.component';
import { TermsAndConditionsComponent } from './main/pages/terms-and-conditions/terms-and-conditions.component';
import { CovidComponent } from './main/pages/covid/covid.component';
import { BuildYourOrderComponent } from './main/build-order/build-your-order/build-your-order.component';
import { SchedulePickupComponent } from './main/build-order/schedule-pickup/schedule-pickup.component';
import { ChooseAddressComponent } from './main/build-order/choose-address/choose-address.component';
import { ConfirmOrderComponent } from './main/build-order/confirm-order/confirm-order.component';
import { AgmCoreModule } from '@agm/core';
import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component';
import { StoreDetailComponent } from './main/pages/store-detail/store-detail.component';
import { UserProfileComponent } from './main/pages/user-profile/user-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CitiesComponent } from './main/pages/cities/cities.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddHeaderInterceptor } from './utils/headerinterceptor';
import { SortPipe } from './main/pipes/sort.pipe';
import { OrderHistoryComponent } from './main/pages/order-history/order-history.component';
import { UserAddressesComponent } from './main/pages/user-addresses/user-addresses.component';
import { ProfileSettingsComponent } from './main/pages/profile-settings/profile-settings.component';
import { MyProfileComponent } from './main/pages/my-profile/my-profile.component'
import { environment } from '../environments/environment';
import { CommonModule, DatePipe } from '@angular/common';
import { OrderConfrimedComponent } from './main/build-order/order-confrimed/order-confrimed.component';
import { GooglePlaceDirective } from '../app/directives/google-place.directive';
import { WeekDayListerComponent } from './main/build-order/week-day-lister/week-day-lister.component';
import { BlogCardComponent } from './blog/blog-card/blog-card.component';
import { NewLoginComponent } from './main/pages/new-login/new-login.component';
import { FranchiseV2Component } from './main/pages/franchise-v2/franchise-v2.component';
import { FranchiseFormComponent } from './main/pages/franchise-form/franchise-form.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LeafletMapComponent } from './main/components/leaflet-map/leaflet-map.component';
import { LoginPageComponent } from './main/auth/login-page/login-page.component';
import { LoginOtpVerificationComponent } from './main/auth/login-otp-verification/login-otp-verification.component';
import { OtpVerificationSuccessComponent } from './main/auth/otp-verification-success/otp-verification-success.component';
import { SignupPageComponent } from './main/auth/signup-page/signup-page.component';
import { AuthWrapperComponent } from './main/auth/auth-wrapper/auth-wrapper.component';
import {MatRippleModule} from '@angular/material/core';
import { DotLoaderComponent } from './main/dot-loader/dot-loader.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { OrderConfirmedSuccessComponent } from './main/pages/order-confirmed-success/order-confirmed-success.component';
import { FranchiseAppliedSuccessComponent } from './main/pages/franchise-applied-success/franchise-applied-success.component';
import { PixelModule } from 'ngx-pixel';
import { DryCleaningComponent } from './main/pages/dry-cleaning/dry-cleaning.component';
import { PremiumLaundryComponent } from './main/pages/premium-laundry/premium-laundry.component';
import { SteamIroningComponent } from './main/pages/steam-ironing/steam-ironing.component';
import { ShoeCleaningComponent } from './main/pages/shoe-cleaning/shoe-cleaning.component';
import { BagCleaningComponent } from './main/pages/bag-cleaning/bag-cleaning.component';
import { CurtainCleaningComponent } from './main/pages/curtain-cleaning/curtain-cleaning.component';
import { SofaCleaningComponent } from './main/pages/sofa-cleaning/sofa-cleaning.component';
import { CarpetCleaningComponent } from './main/pages/carpet-cleaning/carpet-cleaning.component';
import { CarCleaningComponent } from './main/pages/car-cleaning/car-cleaning.component';
import { HomeCleaningComponent } from './main/pages/home-cleaning/home-cleaning.component';
import { LaundryComponent } from './main/pages/laundry/laundry.component';

const MAT_MODULE = [
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule
]
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    BannerComponent,
    AboutComponent,
    ServicesComponent,
    PricingComponent,
    LocateUsComponent,
    OurServicesComponent,
    WhyUsComponent,
    HowItWorkComponent,
    CustomerSectionComponent,
    LatestBlogsComponent,
    LocationsComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    SignUpComponent,
    MainBlogsComponent,
    FranchiseComponent,
    MediaComponent,
    PrivacyPolicyComponent,
    CareerComponent,
    TermsAndConditionsComponent,
    CovidComponent,
    BuildYourOrderComponent,
    SchedulePickupComponent,
    ChooseAddressComponent,
    ConfirmOrderComponent,
    BlogDetailComponent,
    StoreDetailComponent,
    UserProfileComponent,
    CitiesComponent,
    SortPipe,
    OrderHistoryComponent,
    UserAddressesComponent,
    ProfileSettingsComponent,
    MyProfileComponent,
    OrderConfrimedComponent,
    GooglePlaceDirective,
    WeekDayListerComponent,
    BlogCardComponent,
    NewLoginComponent,
    FranchiseV2Component,
    FranchiseFormComponent,
    LeafletMapComponent,
    LoginPageComponent,
    OtpVerificationSuccessComponent,
    LoginOtpVerificationComponent,
    SignupPageComponent,
    AuthWrapperComponent,
    DotLoaderComponent,
    NumberOnlyDirective,
    OrderConfirmedSuccessComponent,
    FranchiseAppliedSuccessComponent,
    DryCleaningComponent,
    PremiumLaundryComponent,
    SteamIroningComponent,
    ShoeCleaningComponent,
    BagCleaningComponent,
    CurtainCleaningComponent,
    SofaCleaningComponent,
    CarpetCleaningComponent,
    CarCleaningComponent,
    HomeCleaningComponent,
    LaundryComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgbModule,
    NgxPaginationModule,
    NgImageSliderModule,
    MAT_MODULE,
    NgOtpInputModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY
    }),
    PixelModule.forRoot({ enabled: true, pixelId: '251134256329342' }),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AddHeaderInterceptor,
    multi: true,
  },
    DatePipe,
    SortPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
