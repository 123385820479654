import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-steam-ironing',
  templateUrl: './steam-ironing.component.html',
  styleUrls: ['./steam-ironing.component.scss']
})
export class SteamIroningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
